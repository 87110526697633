<template>
 <div class="app-home">
  <HeaderLayout></HeaderLayout>
  <div class="user-management">
    <el-card class="search-bar">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item :label="$t('content.user_name')" class="search-item">
          <el-input
            v-model="searchForm.username"
            :placeholder="$t('view.placeholder')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" icon="el-icon-search" @click="searchUsers"
            >{{$t('view.search')}}</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="add-user-btn"
            @click="showAddUserDialog"
            >{{$t('content.add_user')}}</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="user-list">
      <el-table
        :data="userList"
        border
        v-loading="loading"
        height="80vh"
      >
        <el-table-column prop="username" :label="$t('content.user_name')"></el-table-column>
        <el-table-column prop="nickname" :label="$t('content.nick_name')"></el-table-column>
        <el-table-column prop="role" :label="$t('content.role')">
          <template slot-scope="{ row }">
            <el-tag type="success" v-if="row.roleId == 1">{{$t('content.manager_user')}}</el-tag>
            <el-tag v-else-if="row.roleId == 2">{{$t('content.us_user')}}</el-tag>
            <el-tag type="warning" v-else>{{$t('content.ch_user')}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="phone" :label="$t('content.phone')"></el-table-column>
        <el-table-column prop="createTime" :label="$t('list.create_time')">
          <template slot-scope="{ row }">
            {{ formatDate(row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('action.action')">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              icon="el-icon-refresh"
              @click="showResetPasswordDialog(row)"
              >{{$t("content.reset_password")}}</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="showEditUserDialog(row)"
              >{{$t("action.edit")}}</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              @click="showDeleteUserConfirm(row.$index, row)"
              >{{$t("action.delete")}}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog :visible.sync="resetPasswordDialogVisible" :title="$t('content.reset_password')">
      <el-form
        :model="resetPasswordForm"
        label-width="140px"
        ref="resetPasswordForm"
      >
        <el-form-item :label="$t('content.user_name')" style="text-align: left">
          <span>{{ currentSelectedUser.username }}</span>
        </el-form-item>
        <el-form-item :label="$t('content.new_password')" prop="password">
          <el-input
            type="password"
            v-model="resetPasswordForm.password"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('content.confirm_password')" prop="confirmPassword">
          <el-input
            type="password"
            v-model="resetPasswordForm.confirmPassword"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="resetPasswordDialogVisible = false">{{$t("confirm.cancel")}}</el-button>
        <el-button type="primary" @click="resetUserPassword">{{$t("confirm.confirm")}}</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="editUserDialogVisible" :title="userDialogTitle">
      <el-form
        :model="editUserForm"
        :rules="editUserFormRules"
        ref="editUserForm"
        label-width="140px"
      >
        <el-form-item :label="$t('content.user_name')" prop="username">
          <el-input
            v-model="editUserForm.username"
            :disabled="editUserFormDisabled"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('content.nick_name')" prop="nickname">
          <el-input v-model="editUserForm.nickname"></el-input>
        </el-form-item>
        <el-form-item :label="$t('content.role')" prop="roleId" style="width: 60%">
          <el-radio-group v-model="editUserForm.roleId">
            <el-radio :label="1">{{ $t('content.manager_user') }}</el-radio>
            <el-radio :label="2">{{ $t('content.us_user') }}</el-radio>
            <el-radio :label="3">{{ $t('content.ch_user') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('content.phone')" prop="phone">
          <el-input v-model="editUserForm.phone"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="editUserDialogVisible = false">{{ $t('confirm.cancel') }}</el-button>
        <el-button type="primary" @click="submitEditUserForm">{{ $t('confirm.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
 </div>
</template>
<script>
import HeaderLayout from "@/views/Home/layout/HeaderLayout.vue";
export default {
  name: "user-list",
  components: {
    HeaderLayout,
  },
  data() {
    const roles = {
      admin: "管理员",
      normal: "普通用户",
      readonly: "只读用户",
    };
    const roleTagMap = {
      admin: "管理员",
      normal: "普通用户",
      readonly: "只读用户",
    };
    return {
      loading:true,
      isAdd:true,
      searchForm: {
        username: "",
        nickname: "",
      },
      userDialogTitle: "用户信息",
      userList: [],
      roles,
      roleTagMap,
      resetPasswordDialogVisible: false,
      currentSelectedUser: {},
      resetPasswordForm: {
        password: "",
        confirmPassword: "",
      },
      editUserDialogVisible: false,
      editUserFormDisabled: false,
      editUserForm: {},
      editUserFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        nickname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        roleId: [{ required: true, message: "请选择角色", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      },
    };
  },
  methods: {
    searchUsers() {
      // 在这里查询用户列表
      this.getData(this.searchForm);
    },
    showAddUserDialog() {
      this.isAdd = true;
      this.userDialogTitle = this.$t("content.add_user_info");
      this.editUserDialogVisible = true;
      this.editUserFormDisabled = false;
      this.editUserForm = {
        roleId: 1,
        department_id: 1,
      };
    },
    showEditUserDialog(row) {
      this.isAdd = false;
      this.userDialogTitle = this.$t("content.edit_user_info");
      this.editUserDialogVisible = true;
      this.editUserForm = Object.assign({}, row);
      this.editUserFormDisabled = true;
    },
    submitEditUserForm() {
      this.$refs.editUserForm.validate((valid) => {
        if (valid) {
          // 在这里提交用户信息
          this.editUserForm.departmentId = 1;
          this.editUserForm.state = 1;
          this.editUserForm.gender = 1;

          console.log("提交用户信息", this.editUserForm);
          var params = this.editUserForm;
          if (this.isAdd) {
            this.sendAddUser(params);
          } else {
            params.userId = this.editUserForm.id;

            this.$delete(params, "createTime");
            this.$delete(params, "updateTime");
            this.$delete(params, "deleted");
            console.info("params:", params);

            this.sendUpdateUser(params);
          }
          this.editUserDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    sendAddUser(params) {
      this.$newApi
        .userManager.userAdd(params)
        .then(() => {
          this.$notify({
            title: this.$t("confirm.tips"),
            message: this.$t("confirm.success"),
            type: "success",
          });

          this.getData({});
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendUpdateUser(params) {
      this.$newApi
        .userManager.userUpdate(params)
        .then(() => {
          this.$notify({
            title: "温馨提示",
            message: "用户信息更新成功",
            type: "success",
          });
          this.getData();
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showResetPasswordDialog(row) {
      this.resetPasswordDialogVisible = true;
      this.resetPasswordForm = {
        password: "",
        confirmPassword: "",
      };
      this.currentSelectedUser = row;
    },
    resetUserPassword() {
      console.info(this.$refs["resetPasswordForm"]);
      this.$refs.resetPasswordForm.validate((valid) => {
        if (valid) {
          // 在这里提交表单数据到后端
          console.log(
            "重置密码",
            this.currentSelectedUser,
            this.resetPasswordForm
          );
          var params = {
            userId: this.currentSelectedUser.id,
            newPassword: this.resetPasswordForm.password,
            confirmPassword: this.resetPasswordForm.confirmPassword,
          };
          this.$newApi
            .userManager.resetUserPassword(params)
            .then(() => {
              this.$notify({
                title: "温馨提示",
                message: "用户密码重置成功",
                type: "success",
              });
            })
            .catch((err) => {
              console.log(err);
            });
          this.resetPasswordDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    showDeleteUserConfirm(index, row) {
      
      this.$confirm(this.$t("confirm.delete"), this.$t("confirm.tips"), {
        confirmButtonText: this.$t("confirm.confirm"),
        cancelButtonText: this.$t("confirm.cancel"),
        type: "warning",
      }).then(() => {
        // 在这里执行删除用户操作
        var params = row.id;
        this.$newApi
          .userManager.userRemove(params)
          .then(() => {
            this.$notify({
              title: this.$t("confirm.tips"),
              message: this.$t("confirm.success"),
              type: "success",
            });
            this.getData({});
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    formatDate(date) {
      return date;
    },
    getData(params) {
      params.pageSize=30;
      this.loading = true;
      this.$newApi
        .userManager.userList(params)
        .then((data) => {
          console.log(data);
          var user = JSON.parse(localStorage.getItem("specUserInfo"));
          if (user.roleId !== 1) {
            this.$notify.error({
              title: this.$t("confirm.tips"),
              message: "权限不足，请联系管理员",
            });
            this.$router.push({ path: "/home" });
          } else {
            this.userList = data.records;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.$notify.error({
            title: this.$t("confirm.tips"),
            message: "权限不足，请联系管理员",
          });
          this.$router.push({ path: "/home" });
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getData({});
  },
};
</script>
<style scoped>
.user-management {
  padding: 24px;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-bar .search-form {
  margin-right: 24px;
}

.search-bar .add-user-btn {
  margin-left: auto;
}

.user-list {
  margin-top: 24px;
}
</style>